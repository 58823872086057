// import react from "react";
import Input from "../Input";
import { useState } from "react";

import "./Search.css";

const Search = ({ products, productSelected }) => {
  const selectedProduct = (product) => {
    productSelected(product.target.getAttribute("data-number"));
  };

  let [filteredProducts, setFilteredProducts] = useState(products);
  const [inputValue, setInputValue] = useState({
    value: "",
  });

  const userInput = (input) => {
    setInputValue({
      value: input.value,
    });

    setFilteredProducts(products.filter((product) => product.name.includes(inputValue.value)));

    // console.log(inputValue.value);
  };

  return (
    <>
      <div className="header search">
        <Input
          registration={userInput}
          id="search-bar"
          type="text"
          icon="akar-icons:search"
        >
          Pesquisar
        </Input>
      </div>
      {inputValue.value !== "" && (
        <div className="search-container">
          {filteredProducts.map((product, index) => (
            <div className="home-container-item" key={Math.random()}>
              <div
                className="item-text"
                onClick={selectedProduct}
                data-number={product.id}
              >
                <img src={product.image} alt="" srcset="" data-number={product.id} />
                <p data-number={product.id}>
                  {product.name} {product.brand} {product.type} {product.qt}
                </p>
                <p className="item-price" data-number={product.id} >
                  R${product.prices[0].price.toString().replace(".", ",")}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default Search;
