import "./Forms.css";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import Input from "../Input";
import { useState } from "react";

const Forms = ({ id, newItem, resetActive }) => {
  let [newProduct, setNewProduct] = useState({
    name: "",
    brand: "",
    type: "",
    qt: "",
    prices: [
      {
        market: "Extra",
        price: 0,
      },
    ],
    image:
      "",
  });

  let items = [
    {
      name: "product",
      value: [
        {
          name: "name",
          description: "Nome Ex:. Leite",
        },
        {
          name: "brand",
          description: "Marca Ex:. Italac",
        },
        {
          name: "type",
          description: "Tipo Ex:. Desnatado",
        },
        {
          name: "qt",
          description: "Quantidade Ex:. 1L",
        },
        {
          name: "price",
          description: "Preço Ex:. 2.8",
        },
        {
          name: "image",
          description: "Link da imagem",
        },
      ],
    },
    {
      name: "market",
      value: [
        {
          name: "name",
          description: "Nome Ex:. Extra",
        },
        {
          name: "image",
          description: "Link da imagem",
        },
      ],
    },
    {
      name: "prices",
      value: [
        {
          name: "product",
          description: "Nome do produto",
        },
        {
          name: "market",
          description: "Nome do mercado",
        },
        {
          name: "price",
          description: "Preço",
        },
      ],
    },
  ];

  const clickHandler = (e) => {
    resetActive();
  };

  const newReg = (e) => {
    setNewProduct({
      ...newProduct,
      [e.id]: e.value,
    });
    if (e.id === "price") newProduct.prices[0].price = e.value;
  };

  const addReg = (e) => {
    newItem(newProduct);
  };
  let itemIndex = 0;
  id === "market"
    ? (itemIndex = 1)
    : id === "price"
    ? (itemIndex = 2)
    : (itemIndex = 0);

  return (
    <div className="container form">
      <div className="back" onClick={clickHandler}>
        <Icon icon="akar-icons:arrow-back-thick-fill" />
      </div>
      <div className="forms-header"></div>
      <div className="form-body">
        <>
          {/* <Input
          registration={newReg}
          id="name"
          type="text"
          // icon="fa-solid:lock"
        >
          Nome Ex:. Leite
        </Input>
        <Input
          registration={newReg}
          id="brand"
          type="text"
          // icon="fa-solid:lock"
        >
          Marca Ex:. Italac
        </Input>
        <Input
          registration={newReg}
          id="type"
          type="text"
          // icon="fa-solid:lock"
        >
          Tipo Ex:. Desnatado
        </Input>
        <Input
          registration={newReg}
          id="qt"
          type="text"
          // icon="fa-solid:lock"
        >
          Quantidade Ex:. 1L
        </Input>
        <Input
          registration={newReg}
          id="price"
          type="text"
          // icon="fa-solid:lock"
        >
          Preço Ex:. 2.48
        </Input>
        <Input
          registration={newReg}
          id="image"
          type="text"
          char="no-limit"
          // icon="fa-solid:lock"
        >
          Imagem link
        </Input> */}
        </>
        {items[itemIndex].value.map((product, index) => (
          <Input
            registration={newReg}
            id={product.name}
            type="text"
            key={index}
            char="no-limit"
          >
            {product.description}
          </Input>
        ))}

        {newProduct.image !== "" && (
          <div className="item-text">
            <img
              src={newProduct.image}
              onError={(e)=>{e.target.onerror = null; e.target.src="https://previews.123rf.com/images/myvector/myvector1606/myvector160600046/57846791-product-not-available-icon.jpg"}}
              srcSet=""
              alt="Imagem não encontrada"
            />
          </div>
        )}
        <Input type="button" id="Enviar" clickHandler={addReg}>
          Adicionar
        </Input>
      </div>
    </div>
  );
};

export default Forms;
