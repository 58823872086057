const products = [
  {
    name: "Leite",
    brand: "Italac",
    type: "Integral",
    qt: "1L",
    id: 0,
    prices: [
      {
        market: "Extra",
        price: 2.24,
      },
      {
        market: "Walmart",
        price: 2.28,
      },
      {
        market: "Carrefour",
        price: 2.32,
      },
      {
        market: "Lopes",
        price: 2.37,
      },
    ],
    image: "https://static.paodeacucar.com/img/uploads/1/194/531194.jpg",
  },
  {
    name: "Açucar",
    brand: "União",
    type: "Refinado",
    qt: "1KG",
    id: 1,
    prices: [
      {
        market: "Extra",
        price: 4.59,
      },
      {
        market: "Walmart",
        price: 4.65,
      },
      {
        market: "Carrefour",
        price: 4.7,
      },
      {
        market: "Lopes",
        price: 4.77,
      },
    ],
    image:
      "https://carrefourbr.vtexassets.com/arquivos/ids/15813910/197564_1.jpg?v=637540293323270000",
  },
  {
    name: "Macarrão",
    brand: "Adria",
    type: "Parafuso",
    qt: "500g",
    id: 2,
    prices: [
      {
        market: "Extra",
        price: 3.95,
      },
      {
        market: "Walmart",
        price: 4.01,
      },
      {
        market: "Carrefour",
        price: 4.09,
      },
      {
        market: "Lopes",
        price: 4.12,
      },
    ],
    image: "https://static.clubeextra.com.br/img/uploads/1/755/12707755.jpg",
  },
  {
    name: "Leite",
    brand: "Italac",
    type: "Desnatado",
    qt: "1L",
    id: 3,
    prices: [
      {
        market: "Extra",
        price: 2.24,
      },
      {
        market: "Walmart",
        price: 2.28,
      },
      {
        market: "Carrefour",
        price: 2.32,
      },
      {
        market: "Lopes",
        price: 2.37,
      },
    ],
    image: "https://www.clubeextra.com.br/img/uploads/1/804/529804.jpg",
  },
];

export default products;