import "./Product.css";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";

const Product = ({ product, markets }) => {
  return (
    <div className="product-interface">
      <div className="back">
        <Link to="/">
          <Icon icon="akar-icons:arrow-back-thick-fill" />
        </Link>
      </div>
      <div className="product-header">
        <img
          src={product.image}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src =
              "https://previews.123rf.com/images/myvector/myvector1606/myvector160600046/57846791-product-not-available-icon.jpg";
          }}
          alt=""
          srcSet=""
        />
        <p>
          {product.name} {product.type} {product.brand} {product.qt}
        </p>
        <div className="market-price">
          <img className="market-img" src={markets[0].image} alt="" />
          <p className="bestPrice">
            R${product.prices[0].price.toString().replace(".", ",")}
          </p>
        </div>
      </div>
      <div className="prices">
        {product.prices.map((product, index) => (
          <div className="price-item" key={Math.random()}>
            <p className="price-item-market">{product.market}</p>
            <p className="price-item-value">
              R${product.price.toString().replace(".", ",")}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Product;
