import Input from "../Input";
import "./Add.css";
import { useState } from "react";
import Forms from "./Forms";

const Add = ( {addNewItem} ) => {
  let [activeForm, setActiveForm] = useState("");

  const onClickHandler = (e) => {
    setActiveForm(e.target.id);
  }

  const resetActive = (e) => {
    setActiveForm("");
  }

  const newItem = (e) => {
    addNewItem(e);
    setActiveForm("");
  }

  return (
    <div className="add-interface">
      {activeForm === "" ? (
      <div className="header add">
        <Input id="product" type="button" clickHandler={onClickHandler}>
          Adicionar Produto
        </Input>
        <Input id="market" type="button" clickHandler={onClickHandler}>
          Adicionar Mercado
        </Input>
        <Input id="price" type="button" clickHandler={onClickHandler}>
          Adicionar Preço
        </Input>
      </div>) : <Forms id={activeForm} newItem={newItem} resetActive={resetActive}></Forms>}
    </div>
  );
};

export default Add;
