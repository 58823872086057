import React, { useState } from "react";
import "./Menu.css";
import Input from "./Input";
import Home from "./PAGES/Home";
import { Icon } from "@iconify/react";
import Search from "./PAGES/Search";

import { useHistory } from "react-router-dom";
import List from "./PAGES/List";
import User from "./PAGES/User";
import Add from "./PAGES/Add";

// Componente menu, para a página inicial, onde o usuário poderá navegar onde quiser
const Menu = ({ user, products, markets, selectProduct, loggedIn, newItem, addListItem }) => {

  const addNewItem = (e) => {
    newItem(e);
  }

  const history = useHistory();
  user = user[0];
  // Variável do useState armazenando o id (página) em que o usuário está, e colocando o distanciamento necessário
  let [activeMenu, setActiveMenu] = useState({ id: "home", paddingLeft: "0" });

  const isLoggedIn = (state) => {
    loggedIn(state);
  };

  const productSelected = (product) => {
    selectProduct(product);
    history.push("/product");
  };

  const listItem = (e) => {
    addListItem(e);
  }

  // Atualiza a variavel activeMenu, assim que o usuário clicar em um dos items na navbar
  const updateActive = (e) => {
    // let size = window.innerWidth / 5;
    let itemId = e.target.id;

    let paddingLeft =
      itemId === "search"
        ? "75px"
        : itemId === "list"
        ? "250px"
        : itemId === "user"
        ? "325px" 
        : itemId === "add"
        ? "20000px"
        : "0"
    setActiveMenu({ id: e.target.id, paddingLeft: paddingLeft });
  };

  // Começa a fazer a renderização da página
  return (
    <div className="interface">
      {/* Começa a navbar */}
      <nav className="navbar">
        <div
          className="movable-navbar"
          style={{
            // paddingLeft: activeMenu.paddingLeft,
            minWidth: "405px",
          }}
        >
          <div
            className="prev-item"
            style={{
              transform: "translateX(" + activeMenu.paddingLeft + ")",
            }}
          ></div>
          <div
            className="next-item"
            style={{
              transform: "translateX(" + activeMenu.paddingLeft + ")",
            }}
          ></div>
          <div
            className="current-item"
            style={{
              transform: "translateX(" + activeMenu.paddingLeft + ")",
            }}
          ></div>
        </div>
        <ul className="navbar-items">
          <li
            id="home"
            className={
              activeMenu.id === "home" ? "navbar-item active" : "navbar-item"
            }
            onClick={updateActive}
          >
            <Icon icon="ant-design:home-filled" />
          </li>
          <li
            id="search"
            className={
              activeMenu.id === "search" ? "navbar-item active" : "navbar-item"
            }
            onClick={updateActive}
          >
            <Icon icon="akar-icons:search" />
          </li>
          <li
            id="add"
            className="navbar-item navbar-item-circle"
            onClick={updateActive}
          >
            <Icon icon="akar-icons:plus" />
          </li>
          <li
            id="list"
            className={
              activeMenu.id === "list" ? "navbar-item active" : "navbar-item"
            }
            onClick={updateActive}
          >
            <Icon icon="gg:notes" />
          </li>
          <li
            id="user"
            className={
              activeMenu.id === "user" ? "navbar-item active" : "navbar-item"
            }
            onClick={updateActive}
          >
            <Icon icon="bx:bxs-user" />
          </li>
        </ul>
      </nav>
      {/* Começa onde deve estar os items da parte superior, Mercados */}
      {activeMenu.id === "home" ? (
        <Home
          user={user}
          products={products}
          markets={markets}
          productSelected={productSelected}
        />
      ) : activeMenu.id === "search" ? (
        <Search products={products} productSelected={productSelected} />
      ) : activeMenu.id === "list" ? (
        <List user={user} listItem={listItem}/>
      ) : activeMenu.id === "user" ? (
        <User user={user} isLoggedIn={isLoggedIn} />
      ) : (
        activeMenu.id === "add" && <Add addNewItem={addNewItem}></Add>
      )}

      <div className="spacer"></div>
    </div>
  );
};

export default Menu;
