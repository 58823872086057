import React, { useState } from "react";
import "./App.css";
import Home from "./Home";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Signin from "./Signin";
import Login from "./Login";
import Product from "./components/PAGES/Product";
import dataProducts from "./data/products";

import Menu from "./components/Menu";
import { AnimatePresence } from "framer-motion";

function App() {
  let [isLogged, setIsLogged] = useState({
    state: false,
    user: "",
  });
  let [currentProduct, setCurrentProduct] = useState({ product: "" });
  let [users, setUsers] = useState([
    {
      id: 0,
      key: Math.random() * 0.821,
      name: "admin",
      singleName: "admin",
      pw: "03ac674216f3e15c761ee1a5e255f067953623c8b388b4459e13f978d7c846f4",
      email: "admin@email.com",
      image: "https://i1.wp.com/terracoeconomico.com.br/wp-content/uploads/2019/01/default-user-image.png?ssl=1",
      list: [],
    },
  ]);
  let [products, setProducts] = useState(dataProducts);

  let [markets, setMarkets] = useState([
    {
      name: "Extra",
      image:
        "https://publicoa.com.br/wp-content/uploads/2020/10/Capa-Extra.png",
    },
    {
      name: "Carrefour",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmvpbn09rtKcD189GhqH6gSzuw1SgLSJ22CiqDP-WK5jl7v_J2xwgftIuP0lbouGaV-0Y&usqp=CAU",
    },
    {
      name: "Walmart",
      image:
        "https://thumbs.dreamstime.com/b/modelo-de-logotipo-walmart-ilustrativo-em-fundo-branco-%C3%ADcone-logotipos-%C3%ADcones-definir-m%C3%ADdias-sociais-banner-plano-vetores-svg-210443980.jpg",
    },
    {
      name: "Lopes",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQaBF7YLl1AEIW8c64mREGa5gQ3Ci6rU10jGH7VQ3h-AbkbENO2uMVGwEjmePFJF_LBH2c&usqp=CAU",
    },
  ]);

  const selectProduct = (product) => {
    setCurrentProduct(product);
  };

  const loggedIn = (state) => {
    setIsLogged(state);
  };

  const addUser = (user) => {
    setUsers((prevUsers) => {
      return [...prevUsers, user];
    });
  };

  const addNewItem = (product) => {
    product.id = products.length;
    setProducts((prevProducts) => {
      return [...prevProducts, product]
    });
  }

  const addListItem = (item) => {

      setUsers((prevUsers) => [...prevUsers,users[item.id].list = item.value])

  }

  return (
    <Router>
      <AnimatePresence initial={true} exitBeforeEnter>
        <Switch>
          <Route exact path="/">
            {isLogged.state ? (
              <Menu
                user={isLogged.user}
                products={products}
                markets={markets}
                selectProduct={selectProduct}
                loggedIn={loggedIn}
                newItem={addNewItem}
                addListItem={addListItem}
              />
            ) : (
              <>
                <Home />
                <h1
                  className="usuarios"
                  style={{
                    textAlign: "center",
                    color: "#effefe",
                  }}
                >
                  Usuários Cadastrados:
                </h1>
                <div
                  className="users"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    maxWidth: "800px",
                    width: "80%",
                    margin: "auto",
                  }}
                >
                  {users.map((e) => {
                    return (
                      <h2
                        style={{
                          textAlign: "center",
                          color: "#defefe",
                          backgroundColor: "var(--primary)",
                          // width: "fit-content",
                          margin: "10px",
                          padding: ".75rem 1.25rem",
                          borderRadius: "50px",
                        }}
                        key={Math.random() * 0.821}
                      >
                        {e.singleName}
                      </h2>
                    );
                  })}
                </div>
              </>
            )}
          </Route>
          <Route exact path="/product">
            <Product product={products[currentProduct]} markets={markets}></Product>
          </Route>
          <Route exact path="/signin">
            <Signin users={users} addUser={addUser}></Signin>
          </Route>
          <Route exact path="/login">
            <Login users={users} loggedIn={loggedIn}></Login>
          </Route>
        </Switch>
      </AnimatePresence>
    </Router>
  );
}

export default App;
