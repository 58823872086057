// import react from "react";
import Input from "../Input";
import "./List.css";
import { useState } from "react";

const List = ({ user, listItem }) => {

  const [userList, setUserList] = useState({
    id: user.id
  });

  const addInput = (e) => {
    setUserList((prevValues) => ({ ...prevValues,
      value: [...user.list, e.value]
    }));
  };

  const clickHandler = (e) => {
    listItem(userList);
  }

  return (
    <div className="list">
      <div className="header list">
        <Input id="list-input" type="text" registration={addInput}>
          Adicionar
        </Input>
        <Input id="Enviar" type="button" icon="akar-icons:plus" clickHandler={clickHandler}></Input>
      </div>
      <div className="list-items">
        {user.list.map((item, index) => (
          <p>{item}</p>
        ))}
      </div>
    </div>
  );
};

export default List;
