// import react from "react";
import { useDragControls } from "framer-motion";
import Input from "../Input";
import "./User.css"


const User = ({ user, isLoggedIn }) => {

  const loggedIn = () => {
    isLoggedIn({
      state: false,
      user: ""
    });
  }
  return (
    <header className="user">
      <h1 className="title">
        Olá<em>{user.singleName}</em>
      </h1>
      <div className="card">
       <img src={user.image} alt="" />
       <div className="user-name">
       <p>{user.name}</p>
       <p className="user-edit-name">Editar</p>
       </div>
      </div>
      <div className="user-container">
          <div className="user-container-item">
            <div className="item-text">Configurações</div>
          </div>
          <span className="item-spacer"></span>
          <div className="user-container-item">
            <div className="item-text">Localização</div>
          </div>
          <span className="item-spacer"></span>
          <div className="user-container-item">
            <div className="item-text disconnect" onClick={loggedIn}>Desconectar</div>
          </div>
        </div>
    </header>
  );
};

export default User;
